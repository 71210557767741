import React from 'react'
import SiteHeader from '../minis/siteheader'
import * as styles from "../../styles/main/header.module.scss"
import SiteLink from '../minis/siteLink'
import '../../utils/fontawesome'
import { Link, navigate } from "gatsby"

export default function Header(){
    return(
        <div className={styles.container}>
            <div className={styles.headerWrapper}>
                <Link to="/" className={styles.logoComb}>
                    <div className={styles.headerLogo} />
                    <SiteHeader style={styles.fontSize} onClick={
                        event => {
                            event.preventDefault()
                            navigate("/")
                        }
                    }/>
                </Link>

                <div className={styles.linksContainer}>
                    {
                    /** FOR VERSION 2
                    <SiteLink 
                    style={styles.links}
                    url='#'
                    linkTitle='Journal'
                    icon={'book'}
                    iconStyle={styles.iconStyle}
                    />
                    */
                    }
                    <SiteLink
                        style={styles.links} 
                        url='/contact'
                        linkTitle='Contact'
                        icon={'envelope'}
                        iconStyle={styles.iconStyle}
                    />
                </div>
            </div>
        </div>
    )
}