import React from 'react'
import * as styles from "../../styles/minis/siteheader.module.scss"
export default function SiteHeader({ style }){
    
    return(
        <div className={`${styles.siteHeader} ${style}`}>
            <div className={styles.gray}>THE</div>
            <div className={styles.blue}>&nbsp;CASUAL&nbsp;</div>
            <div className={styles.red}>DEV</div>
        </div>
    )
}