import React from 'react'
import * as styles from "../../styles/main/footer.module.scss"
import '../../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Clipboard from 'react-clipboard.js'
import {notify} from 'react-notify-toast';

export default function Header(){


    let notificationColor = { background: "#28a74585", text: "#FFFFFF" };

    const copyEmail = () => {
        notify.show('You have copied my email to clipboard!', "custom", 5000, notificationColor);
        //notify.show('You have copied my email to clipboard!');
    }

    const copyMobile = () => {
        notify.show('You have copied my mobile to clipboard!', "custom", 5000, notificationColor);       
        //notify.show('You have copied my mobile to clipboard!');
    }

    return(
        <div className={styles.footer}>
            <div className={styles.contentWrapper}>
                <div className={styles.copyElement}>
                    <a href ={"mailto: "+process.env.email}>{process.env.email}</a>
                    <Clipboard data-clipboard-text={process.env.email} onClick={copyEmail}>
                        <FontAwesomeIcon icon='link' className={styles.icon} />
                    </Clipboard>
                </div>
                <a href ={process.env.linkedin} target="_blank" rel="noopener noreferrer">linkedin</a>
                <a href ={process.env.github} target="_blank" rel="noopener noreferrer">github</a>
                <a href ={process.env.twitter} target="_blank" rel="noopener noreferrer">twitter</a>
                <div className={styles.copyElement}>
                    <span>{process.env.mobile}</span>
                    <Clipboard data-clipboard-text={process.env.mobile} onClick={copyMobile}>
                        <FontAwesomeIcon icon='link' className={styles.icon}/>
                    </Clipboard>
                    
                </div>
            </div>
        </div>
    )
}