import React from 'react'
import * as styles from "../../styles/main/body.module.scss"
export default function Body({ children }){
    return(
        <div className={styles.content}>
            <div className={styles.contentWrapper}>
                {children}
            </div>
        </div>
    )
}